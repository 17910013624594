import { initializeErrorReporting } from '@eventbrite/error-reporting';
import {
    getLocaleOverride,
    getParentLocale,
    setupAsync,
} from '@eventbrite/i18n';
import {
    initDimensionsFromState,
    trackInitialPageView,
} from '@eventbrite/site-analytics';
import { Event } from '@sentry/types';
import React from 'react';
import { render } from 'react-dom';
import { App } from './app';
import './styles/listings-protected.scss';

// retrieve data from server to hydrate on the client
const props = window.__SERVER_DATA__ || ({} as any);

const parentLocale = getParentLocale(
    getLocaleOverride() || props.env.localeInfo.locale,
);

const SENTRY_TRACES_SAMPLE_RATE = 0.5;

const ignoredErrors = [
    /Script error for "analyticsjs"/i,
    /Script error for "grylls"/i,
    /Script error for "grylls_config"/i,
    /Load timeout for modules: grylls/i,
    /heap.resetIdentity is not a function/i,
    /Error calling eventclick/i,
    /al_onPoststitialDismiss/i,
    /al_onAdViewRendered/i,
    /TypeError: Load failed/i,
];

const ignoreError = (event: Event) => {
    const errorMessage = event?.exception?.values?.[0]?.value;

    if (errorMessage) {
        const isAnIgnoredEvent =
            ignoredErrors.findIndex((ignoredError) =>
                ignoredError.test(errorMessage),
            ) !== -1;

        return isAnIgnoredEvent;
    }

    return false;
};

const main = async () => {
    try {
        initializeErrorReporting({
            dsn: props.settings.sentryDNS,
            tracesSampleRate: SENTRY_TRACES_SAMPLE_RATE,
            ignoreError,
            metaData: {
                tags: {
                    event_id: props.event.id,
                },
            },
        });
        if (parentLocale !== 'en_US') {
            await setupAsync({
                parentLocale,
                translations: /* webpackChunkName: "i18n-translation" */ import(
                    `./i18n/translations/${parentLocale}.json`
                ),
            });
        }
    } finally {
        const rootElement = document.getElementById('root');
        if (rootElement) {
            render(<App {...props} />, document.getElementById('root'));
        }
    }
};
main();

initDimensionsFromState(props);
trackInitialPageView(props.gaSettings);
