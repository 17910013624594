import React from 'react';

type Props = {
    hasCroppedLogo: boolean;
    croppedLogoUrl50?: string;
};

export const MainBodyBackground = ({
    hasCroppedLogo,
    croppedLogoUrl50,
}: Props) => {
    const BackgroundImage = () => (
        <>
            <img className="listing-hero-image" src={croppedLogoUrl50} alt="" />
            <div className="hero-image-overlay"></div>
        </>
    );

    const BackgroundColor = () => (
        <div
            className="listing-hero--no-image listing-hero--no-image__bkg clrfix fx--delay-6 fx--fade-in"
            data-testid="main-body-background-color"
        ></div>
    );

    return (
        <div className="listing-hero-image__blurry-background fx--fade-in fx--delay-4">
            {hasCroppedLogo ? <BackgroundImage /> : <BackgroundColor />}
        </div>
    );
};
