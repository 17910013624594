import React from 'react';

type Props = {
    nameTruncated: string;
    name: string;
};

export const EventName = ({ nameTruncated, name }: Props) => (
    <>
        <h1 className="listing-hero-title text-epic">{nameTruncated}</h1>
        <meta content={name}></meta>
    </>
);
