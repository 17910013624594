import * as React from 'react';

const MailSvg = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        id="mail_svg__eds-icon--mail_svg"
        x={0}
        y={0}
        viewBox="0 0 24 24"
        xmlSpace="preserve"
        {...props}
    >
        <path
            id="mail_svg__eds-icon--mail_base"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3 6.5l9 7.4 9-7.4V18H3V6.5zm.9-.5H20l-8 6.6L3.9 6zM2 5v14h20V5H2z"
        />
    </svg>
);

MailSvg.displayName = 'MailSvg';
export default MailSvg;
