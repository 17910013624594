import { ContactOrganizerButton } from '@eventbrite/contact-organizer-button';
import { SIZE_RESPONSIVE, STYLE_NEUTRAL } from '@eventbrite/eds-button';
import { gettext } from '@eventbrite/i18n';
import React from 'react';
import { AppProps } from '../../types';

export type SubFooterInfoProps = Pick<
    AppProps,
    'event' | 'gaSettings' | 'organizer' | 'settings' | 'user'
>;

export const SubFooterInfo = (props: SubFooterInfoProps) => {
    const {
        event: {
            id: eventId,
            shouldShowPasswordField,
            isEnded,
            name: eventName,
        },
        gaSettings: { category },
        organizer: { id: organizerId },
        settings: { captchaKey },
        user: { isAuthenticated },
    } = props;

    const subFooterLabel = () => {
        if (isEnded) {
            return gettext('This event has ended');
        } else if (shouldShowPasswordField) {
            return gettext('Need a password?');
        } else {
            return gettext('Need an invitation?');
        }
    };

    return (
        <>
            <p className="listing-hero-sub-footer__contact-header listings-label--protected">
                {subFooterLabel()}
            </p>
            <ContactOrganizerButton
                organizerId={organizerId}
                isVisible={false}
                captchaKey={captchaKey}
                isAuthenticated={isAuthenticated}
                eventName={eventName}
                eventId={eventId.toString()}
                gaCategory={category}
                buttonStyle={STYLE_NEUTRAL}
                buttonSize={SIZE_RESPONSIVE}
            />
        </>
    );
};
