import { Icon } from '@eventbrite/eds-icon';
import { IconButton } from '@eventbrite/eds-icon-button';
import { ArrowRight, Lock } from '@eventbrite/eds-iconography';
import {
    InputField,
    MIN_ASIDE_SPACING_SUFFIX,
    STYLE_DYNAMIC,
} from '@eventbrite/eds-input-field';
import { gettext } from '@eventbrite/i18n';
import React from 'react';
import type { FormDataProps } from '../../types';

type Props = FormDataProps & {
    passwordFormActionUrl: string;
    csrfToken: string;
    affiliateCode?: string;
    referralCode?: string;
    inviteKey?: string;
};

export const PasswordForm = ({
    passwordFormActionUrl,
    invalidPassword,
    csrfToken,
    affiliateCode,
    referralCode,
    inviteKey,
}: Props) => {
    const PasswordField = () => {
        return (
            <div className="protected-event-password">
                <InputField
                    id="event_password"
                    name="event_password"
                    label={gettext('Password')}
                    value=""
                    style={STYLE_DYNAMIC}
                    hasError={invalidPassword}
                    minAsideSpacing={MIN_ASIDE_SPACING_SUFFIX}
                    prefix={
                        <Icon type={<Lock />} size="small" color="grey-300" />
                    }
                    suffix={
                        <IconButton
                            iconType={<ArrowRight />}
                            title={gettext('Submit')}
                            type="submit"
                            color="grey-300"
                        />
                    }
                    type="password"
                    required={true}
                />
            </div>
        );
    };

    const HiddenInputs = () => (
        <>
            <input
                type="hidden"
                name="csrfmiddlewaretoken"
                data-testid="csrfmiddlewaretoken"
                value={csrfToken}
            />
            <input type="hidden" name="affiliate" value={affiliateCode} />
            <input type="hidden" name="referral_code" value={referralCode} />
            <input type="hidden" name="invite" value={inviteKey} />
        </>
    );

    return (
        <form
            action={passwordFormActionUrl}
            method="post"
            target="_top"
            data-testid="password-form"
        >
            <PasswordField />
            <HiddenInputs />
        </form>
    );
};
