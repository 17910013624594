import type { CoreEnv } from '@eventbrite/context-gen';
import { Icon } from '@eventbrite/eds-icon';
import { Lock, Mail } from '@eventbrite/eds-iconography';
import { RenderGlobalNavOrUndefined } from '@eventbrite/global-nav';
import { gettext } from '@eventbrite/i18n';
import { SiteStructure } from '@eventbrite/site-structure';
import * as Sentry from '@sentry/react';
import Cookies from 'cookies-js';
import React, { useEffect, useState } from 'react';
import { AreaAddress } from './components/AreaAddress/AreaAddress';
import { EventName } from './components/EventName/EventName';
import { Footer } from './components/Footer/Footer';
import { MainBodyBackground } from './components/MainBodyBackground/MainBodyBackground';
import { NotificationBar } from './components/NotificationBar/NotificationBar';
import { OrganizerInfo } from './components/OrganizerInfo/OrganizerInfo';
import { PasswordForm } from './components/PasswordForm/PasswordForm';
import { StartDate } from './components/StartDate/StartDate';
import { SubFooterInfo } from './components/SubFooterInfo/SubFooterInfo';
import { AppProps } from './types';

const FEATURE_FLAGS = {
    launchCreatorCreditsSectionAttendee: true,
};

export const App = ({
    env,
    request,
    user,
    footerLinks,
    event,
    organizer,
    settings,
    gaSettings,
    notification,
    formData,
}: AppProps) => {
    const {
        hasCroppedLogo,
        croppedLogoUrl50,
        shouldShowPasswordField,
        hideStartDate,
        startDateTime,
        displayStartDate,
        displayAreaAddress,
        nameTruncated: eventNameTruncated,
        name: eventName,
        passwordFormActionUrl,
        inviteKey,
    } = event;

    const { nameTruncated: organizerNameTruncated, url: organizerUrl } =
        organizer;
    const { affiliateCode, referralCode } = request;
    const [csrfToken, setCSRFToken] = useState('');

    useEffect(() => {
        setCSRFToken(Cookies.get('csrftoken'));
    }, []);

    const mainIconProps = shouldShowPasswordField
        ? {
              type: <Lock />,
              title: gettext('Event protected by password'),
          }
        : {
              type: <Mail />,
              title: gettext('Event protected by invitation'),
          };

    const cleanEsiCachePaths = (env: CoreEnv) => {
        const esiPath = user.isAuthenticated
            ? /%2Fesi_nocache/g
            : /%2Fesi_cache/g;
        const removeEsiPath = (string: string): string =>
            string.replace(esiPath, '');

        env.loginUrl = removeEsiPath(env.loginUrl);
        env.logoutUrl = removeEsiPath(env.logoutUrl);
        env.signinUrl = removeEsiPath(env.signinUrl);
        env.signupUrl = removeEsiPath(env.signupUrl);
    };

    cleanEsiCachePaths(env);
    const ff = settings?.featureFlags;
    const enableIaGlobalNav = ff?.enableIaGlobalNav ?? false;
    return (
        <Sentry.ErrorBoundary
            fallback={<>{gettext('Error on Listings SSR')}</>}
        >
            <SiteStructure
                env={env}
                request={request}
                user={user}
                preview={event.isPreview}
                featureFlags={FEATURE_FLAGS}
                globalHeader={RenderGlobalNavOrUndefined({
                    config: { env, user },
                    featureFlag: enableIaGlobalNav,
                })}
                fullScreenMain={true}
                footer={<Footer {...footerLinks} serverUrl={env.serverUrl} />}
            >
                {notification.message && (
                    <NotificationBar
                        message={notification.message}
                        type={notification.type}
                    />
                )}
                <article
                    className={`event-listing ${
                        hasCroppedLogo ? 'event-listing--has-image' : ''
                    }`}
                >
                    <MainBodyBackground
                        hasCroppedLogo={hasCroppedLogo}
                        croppedLogoUrl50={croppedLogoUrl50}
                    />
                    <div className="event-listing__header clrfix">
                        <span className="listing-hero-image__locked">
                            <Icon
                                type={mainIconProps.type}
                                size="medium"
                                color="grey-600"
                                title={mainIconProps.title}
                            />
                        </span>
                        <div className="listing-hero-container--protected">
                            <div className="clrfix fx--delay-6 fx--fade-in">
                                <div className="g-grid">
                                    <div className="g-group">
                                        <div className="g-cell g-cell-10-12 g-cell-lg-8-12 g-offset-lg-2-12 g-offset-1-12">
                                            <div className="listing-hero-header">
                                                {!hideStartDate && (
                                                    <StartDate
                                                        startDateTime={
                                                            startDateTime
                                                        }
                                                        displayStartDate={
                                                            displayStartDate
                                                        }
                                                    />
                                                )}

                                                <AreaAddress
                                                    displayAreaAddress={
                                                        displayAreaAddress
                                                    }
                                                />
                                            </div>
                                            <div className="listing-hero-body">
                                                <EventName
                                                    nameTruncated={
                                                        eventNameTruncated
                                                    }
                                                    name={eventName}
                                                />
                                            </div>
                                            {organizerNameTruncated && (
                                                <div className="listing-hero-footer">
                                                    <OrganizerInfo
                                                        url={organizerUrl}
                                                        nameTruncated={
                                                            organizerNameTruncated
                                                        }
                                                    />
                                                </div>
                                            )}
                                            <hr className="l-mar-top-5 hide-small" />
                                            <div className="g-group l-mar-top-5 listing-hero-sub-footer">
                                                {shouldShowPasswordField && (
                                                    <div className="g-cell g-cell-12-12 g-cell-md-6-12 g-cell--no-gutters">
                                                        <PasswordForm
                                                            invalidPassword={
                                                                formData.invalidPassword
                                                            }
                                                            passwordFormActionUrl={
                                                                passwordFormActionUrl
                                                            }
                                                            csrfToken={
                                                                csrfToken
                                                            }
                                                            affiliateCode={
                                                                affiliateCode
                                                            }
                                                            referralCode={
                                                                referralCode
                                                            }
                                                            inviteKey={
                                                                inviteKey
                                                            }
                                                        />
                                                    </div>
                                                )}
                                                <div
                                                    className={`g-cell g-cell-12-12 g-cell-md-6-12 g-cell--no-gutters listing-hero-sub-footer__contact-container
                                                    ${
                                                        shouldShowPasswordField
                                                            ? ' l-align-right l-sm-align-left'
                                                            : ''
                                                    }
                                                    `}
                                                >
                                                    <SubFooterInfo
                                                        event={event}
                                                        organizer={organizer}
                                                        gaSettings={gaSettings}
                                                        settings={settings}
                                                        user={user}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </article>
            </SiteStructure>
        </Sentry.ErrorBoundary>
    );
};
