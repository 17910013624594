import React from 'react';

type Props = {
    startDateTime: string;
    displayStartDate: string;
};

export const StartDate = ({ startDateTime, displayStartDate }: Props) => (
    <time className="listing-hero-date" dateTime={startDateTime}>
        {displayStartDate}
    </time>
);
