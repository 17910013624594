import * as React from 'react';

const ArrowRightSvg = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        className="arrow-right_svg__eds-icon--right-arrow_svg"
        viewBox="0 0 24 24"
        {...props}
    >
        <path d="M20 12l-5.8-6-.8.7 4.7 4.8H4v1h14.1l-4.7 4.8.7.7 5.9-6z" />
    </svg>
);

ArrowRightSvg.displayName = 'ArrowRightSvg';
export default ArrowRightSvg;
