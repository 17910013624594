import * as React from 'react';

const LockSvg = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        className="lock_svg__eds-icon--lock__svg"
        viewBox="0 0 24 24"
        {...props}
    >
        <path
            className="lock_svg__eds-icon--lock__keyhole"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16 10H8V7c0-2.2 1.8-4 4-4s4 1.8 4 4v3zm1 0h3v12H4V10h3V7c0-2.8 2.2-5 5-5s5 2.2 5 5v3zm2 11H5V11h14v10z"
        />
        <path
            className="lock_svg__eds-icon--lock__base"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12 13c-1.1 0-2 .9-2 2 0 .9.6 1.7 1.5 1.9V19h1v-2.1c.9-.2 1.5-1 1.5-1.9 0-1.1-.9-2-2-2zm0 3c-.6 0-1-.4-1-1s.4-1 1-1 1 .4 1 1-.4 1-1 1z"
        />
    </svg>
);

LockSvg.displayName = 'LockSvg';
export default LockSvg;
