import type {
    CoreApplicationContext,
    CoreEnv,
    CoreRequest,
    CoreUser,
    GASettingKeys,
} from '@eventbrite/context-gen';
import type { FooterLinks } from '../components/Footer/Footer';

export interface Event {
    name: string;
    id: string;
    hasCroppedLogo: boolean;
    hideStartDate: boolean;
    displayStartDate: string;
    isEnded: boolean;
    startDateTime: string;
    shouldShowPasswordField: boolean;
    nameTruncated: string;
    passwordFormActionUrl: string;
    inviteKey: string | '';
    displayAreaAddress: string;
    croppedLogoUrl50: string;
    isPreview: boolean;
}

export interface User extends CoreUser {
    id?: number;
}

export interface Organizer {
    id: string;
    nameTruncated: string;
    url: string;
}

export type Settings = {
    baseUrl: string;
    isMobile: boolean;
    featureFlags?: {
        enableIaGlobalNav?: boolean;
    };
    sentryDNS: string;
    captchaKey: string;
    csrfToken: string;
};

export interface GASettings extends GASettingKeys {
    category: string;
}

export type AffiliateInformation = {
    referralCode: string | '';
    affiliateCode: string | '';
};

export enum NotificationType {
    ERROR = 'error',
    INFO = 'info',
}

export type NotificationProps = {
    message: string | null;
    type?: NotificationType | null;
};

export type FormDataProps = {
    invalidPassword: boolean | null;
};

export interface AppProps extends CoreApplicationContext {
    app_name: string;
    app_version: string;
    env: CoreEnv;
    user: User;
    event: Event;
    settings: Settings;
    organizer: Organizer;
    request: CoreRequest & AffiliateInformation;
    footerLinks: FooterLinks;
    gaSettings: GASettings;
    notification: NotificationProps;
    formData: FormDataProps;
}

export enum Environment {
    DEV = 'dev',
    QA = 'qa',
    PROD = 'prod',
}
