import type { CoreFooterLink, CoreFooterLinks } from '@eventbrite/context-gen';
import { gettext } from '@eventbrite/i18n';
import React from 'react';
import './Footer.scss';
import { FooterLogo } from './FooterLogo';

export interface FooterLinks extends CoreFooterLinks {
    connectLinks?: CoreFooterLink[];
}

export interface FooterProps extends FooterLinks {
    serverUrl: string;
}

export type FooterList = {
    links: FooterLink[];
    heading: string;
    id: string;
};

export interface FooterLink extends CoreFooterLink {
    id?: string;
}

export const FooterList: React.FC<FooterList> = ({ links, heading, id }) => {
    const compare = (a: FooterLink, b: FooterLink): number => {
        if (a.position < b.position) {
            return -1;
        }
        if (a.position > b.position) {
            return 1;
        }
        return 0;
    };
    links.sort(compare);

    return (
        <div>
            <h3 id={id} className="footer-heading">
                {heading}
            </h3>
            <ul aria-labelledby={id}>
                {links.map((link: FooterLink, i: number) => {
                    return (
                        <li key={i}>
                            <a
                                className="footer-link"
                                href={link.link}
                                id={link.id}
                            >
                                {link.title}
                            </a>
                        </li>
                    );
                })}
            </ul>
        </div>
    );
};

export const Footer = ({
    popularSearches,
    thirdFooterColumn,
    connectLinks = {} as any,
    eventsToPlan,
    serverUrl,
}: FooterProps) => {
    return (
        <footer className="listings-footer">
            <div className="footer-wrapper">
                <h2 id="footer-heading" className="eds-is-hidden-accessible">
                    {gettext('Site Navigation')}
                </h2>
                <nav aria-labelledby="footer-heading" className="footer-nav">
                    <FooterList
                        id="use-eventbrite"
                        heading={gettext('Use Eventbrite').toString()}
                        links={popularSearches}
                    />
                    <FooterList
                        id="plan-events"
                        heading={gettext('Plan events').toString()}
                        links={eventsToPlan}
                    />
                    <FooterList
                        id="find-events"
                        heading={gettext('Find events').toString()}
                        links={thirdFooterColumn}
                    />
                    <FooterList
                        id="connect-with-us"
                        heading={gettext('Connect with us').toString()}
                        links={connectLinks}
                    />
                </nav>

                <div className="secondary-footer">
                    <a href={`${serverUrl}/r/ereg`} className="footer-logo">
                        <FooterLogo />
                        <span className="eds-is-hidden-accessible">
                            Eventbrite + Ticketfly
                        </span>
                    </a>
                    <p className="footer-copyright">
                        © {new Date().getFullYear()} Eventbrite
                    </p>
                </div>
            </div>
        </footer>
    );
};
