import { gettext } from '@eventbrite/i18n';
import React from 'react';

type Props = {
    url: string;
    nameTruncated: string;
};

export const OrganizerInfo = ({ url, nameTruncated }: Props) => (
    <div className="l-media clrfix listing-organizer-title">
        <div className="l-align-left">
            <a
                id="organizer-link"
                href={url}
                target="_blank"
                rel="noreferrer"
                className="listing-organizer-name text-default"
            >
                {gettext('by %(name)s', {
                    name: nameTruncated,
                })}
            </a>
        </div>
    </div>
);
