import {
    NotificationBar as EdsNotificationBar,
    TYPE_ERROR,
    TYPE_NEUTRAL,
} from '@eventbrite/eds-notification';
import React from 'react';
import { NotificationProps, NotificationType } from '../../types';

export const NotificationBar: React.FC<NotificationProps> = ({
    message,
    type,
}) => {
    if (!message) {
        return null;
    }

    const notificationType =
        type === NotificationType.ERROR ? TYPE_ERROR : TYPE_NEUTRAL;

    return (
        <EdsNotificationBar type={notificationType} hasCloseButton={true}>
            <div
                className="eds-text-bm"
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                    __html: message.toString(),
                }}
            />
        </EdsNotificationBar>
    );
};
